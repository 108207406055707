<template>
	<modal class="NoxModalPhoenixTrading" name="NoxModalPhoenixTrading" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'deleteSymbol'">Удаление символа</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'deleteSymbol'">Вы действительно хотите удалить символ?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-if="noxType == 'deleteSymbol'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxType: '',
			noxIndex: 0,
			noxSymbol: 0,
			noxBehavior: 0,
			noxStrategy: 0,
			noxIsFullAvg: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxSymbol = event.params.symbol;
				this.noxBehavior = event.params.behavior;
				this.noxStrategy = event.params.strategy;
				this.noxIsFullAvg = event.params.is_full_avg;
				this.noxIsLoading = false;
				this.noxIsError = false;
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать символ.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный символ.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать поведение.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верное поведение.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать стратегию.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верную стратегию.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать FA.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный FA.'; }
				else if (i == 9) { this.noxTemp = 'Символ не найден в системе.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'deleteSymbol') {
						config.url = '/v2/admin/account/phoenix/trading';
						config.data = { symbol: _this.noxSymbol, behavior: _this.noxBehavior, strategy: _this.noxStrategy, is_full_avg: _this.noxIsFullAvg };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'deleteSymbol') {
									_this.$parent.noxPhoenixTradingData.splice(_this.noxIndex, 1);
									_this.$parent.noxToggleAccordionIndex = -1;
									_this.$parent.noxIsToggleAccordion = false;
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'SYMBOL_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									else if (data.response.data.error == 'SYMBOL_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									else if (data.response.data.error == 'BEHAVIOR_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
									else if (data.response.data.error == 'BEHAVIOR_NOT_VALID') { _this.noxAlert = _this.getError(4); }
									else if (data.response.data.error == 'STRATEGY_IS_EMPTY') { _this.noxAlert = _this.getError(5); }
									else if (data.response.data.error == 'STRATEGY_NOT_VALID') { _this.noxAlert = _this.getError(6); }
									else if (data.response.data.error == 'IS_FULL_AVG_IS_EMPTY') { _this.noxAlert = _this.getError(7); }
									else if (data.response.data.error == 'IS_FULL_AVG_NOT_VALID') { _this.noxAlert = _this.getError(8); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (data.response.data.error == 'SYMBOL_NOT_FOUND') { _this.noxAlert = _this.getError(9); }
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalPhoenixTrading');
			}
		}
	}
</script>
